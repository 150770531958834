import React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import Input from "../../components/forms/Input"
import TextArea from "../../components/forms/TextArea"

const Contact1 = ({ className }) => {
  return (
    <>
      <div>
        <Section className={` bg-primary-25 overflow-hidden  ${className}`}>
          <Container>
            {/* section-wrap */}
            <div className="grid grid-cols-1 laptop:grid-cols-12">
              {/* left-part */}
              <div
                className="col-span-6 pb-12 tablet:pb-24 laptop:pb-0
               laptop:pr-[112px]"
              >
                {/* contact */}
                <div className="flex flex-col max-w-[480px] mx-auto gap-8 tablet:gap-14">
                  {/* texts */}
                  <div className="flex flex-col max-w-[448px] gap-6">
                    <SectionTitle className="!pb-0">
                      Contact <span className="text text-primary-600">us</span>
                    </SectionTitle>
                    <SectionText className="!pb-0">
                      Collaboratively administrate empowered markets via
                      plug-and-play networks.
                    </SectionText>
                  </div>
                  {/* from-and-button */}
                  <form className="flex flex-col gap-8">
                    {/* from */}
                    <div className="flex flex-col gap-6 ">
                      <div className="flex flex-col gap-6 tablet:flex-row">
                        <Input
                          label="First name"
                          placeholder="John"
                          name="first-name"
                        />
                        <Input
                          label="Last name"
                          placeholder="Doe"
                          name="last-name"
                        />
                      </div>
                      <Input
                        type="email"
                        label="Email"
                        placeholder="johndoe@example.com"
                        name="email"
                      />
                      <TextArea label="Additional details" name="textarea" />
                      <Input
                        type="checkbox"
                        id="Checkbox4"
                        name="checkbox"
                        label={
                          <>
                            I agree to the{" "}
                            <Link to="#">
                              <span className="font-medium text-body-md text-primary-600">
                                privacy policy.
                              </span>{" "}
                            </Link>
                          </>
                        }
                      />
                    </div>
                    <Button type="submit" size={"xl"}>
                      Send message
                    </Button>
                  </form>
                </div>
              </div>

              {/* right-part */}
              <div className="col-span-6 Laptop:pl-10 ">
                <div data-aos="fade-left" className="relative inline-block">
                  <StaticImage
                    src="../../images/contact-1.png"
                    alt=""
                    className="relative z-20 rounded-3xl"
                  />
                  <div className="absolute z-10 h-[110%] w-full -translate-y-1/2 left-8 top-1/2">
                    <div
                      data-aos="fade-up-right"
                      data-aos-delay="600"
                      className="w-full h-full rounded-3xl bg-secondary-100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Contact1
