import React from "react"
import { Link } from "gatsby"

import Section from "../../components/Section"
import BrandLogo1 from "../../images/svg/brand-logo-1.svg"
import BrandLogo2 from "../../images/svg/brand-logo-2.svg"
import BrandLogo3 from "../../images/svg/brand-logo-3.svg"
import BrandLogo4 from "../../images/svg/brand-logo-4.svg"
import BrandLogo5 from "../../images/svg/brand-logo-5.svg"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import ButtonArrow from "../../components/ButtonArrow"
import AboutCard1 from "../../components/cards/AboutCard1"
import CountUp from "../../components/CountUp"

const About4 = () => {
  return (
    <>
      <Section>
        <Container>
          <div className="flex flex-col gap-12 tablet:gap-20 laptop:gap-[100px]">
            {/* section-wrap */}
            <div className="grid grid-cols-1 laptop:grid-cols-2">
              {/* left-part */}
              <div className="pr-0 pb-10 tablet:pb-16 laptop:pb-0 laptop:pr-[112px]">
                <div data-aos="fade-up">
                  <SectionTitle className="">
                    We lead the way to{" "}
                    <span className="text text-primary-600">better </span>
                    business
                  </SectionTitle>
                  <SectionText className="">
                    Phosfluorescently leverage others enterprise-wide "outside
                    the box" with e-business collaboration and idea-sharing.
                    other resource-leveling convergence rather than
                    inter-mandated networks.
                  </SectionText>
                  <Link className="inline-block !pr-2" to="/about-2">
                    <ButtonArrow>Learn more</ButtonArrow>
                  </Link>
                </div>
              </div>
              {/* right-part */}
              <div className="">
                {/* cards */}
                <div className="grid grid-cols-1 gap-6 tablet:gap-10 tablet:grid-cols-2">
                  <AboutCard1
                    title={<CountUp end={50} suffix="M+" />}
                    text="Our Happy Customer"
                    className=""
                  ></AboutCard1>
                  <AboutCard1
                    title={<CountUp end={150} suffix="+" />}
                    text="Our Business Partner"
                    className=""
                  ></AboutCard1>
                  <AboutCard1
                    title={<CountUp end={50} suffix="+" />}
                    text="Best Service Awards"
                    className=""
                  ></AboutCard1>
                  <AboutCard1
                    title={<CountUp end={450} suffix="+" />}
                    text="Product Portfolio "
                    className=""
                  ></AboutCard1>
                </div>
              </div>
            </div>
            {/* bottom-part */}
            <div className="">
              <div className="flex flex-wrap items-center justify-center laptop:gap-14 tablet:gap-10 gap-7">
                <div data-aos="zoom-in">
                  <BrandLogo1></BrandLogo1>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogo2></BrandLogo2>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogo3></BrandLogo3>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogo4></BrandLogo4>
                </div>
                <div data-aos="zoom-in">
                  <BrandLogo5></BrandLogo5>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default About4
