import React from "react"
import { PlayFill } from "react-bootstrap-icons"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import ListItem from "../../components/ListItem"

const Content1 = ({ className }) => {
  return (
    <>
      <Section className={`bg-neutral-50 overflow-hidden ${className}`}>
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="relative col-span-5 pb-16 tablet:pb-24 laptop:pb-0 ">
              <div className="relative z-20">
                <StaticImage
                  data-aos="fade-up-right"
                  src="../../images/feature-2.png"
                  alt=""
                  className="w-full rounded-3xl"
                />
                <a
                  href="https://www.youtube.com/watch?v=IUN664s7N-c"
                  target="_blank"
                  rel="noreferrer"
                  className="absolute z-30 bottom-4 right-4 animate-pulse"
                >
                  <Button
                    className="bg-black border-none"
                    icon={<PlayFill />}
                    iconRight={false}
                  >
                    Play video
                  </Button>
                </a>
              </div>
              <div className="absolute w-full h-full top-0 left-0  z-10 -translate-x-[8%] -translate-y-[8%]">
                <div
                  data-aos="fade-up-left"
                  className="w-full h-full rounded-tl-none rounded-3xl bg-primary-50"
                >
                  <div className="absolute  bottom-full right-full shape2 w-[42px] h-[42px] bg-neutral-100"></div>
                </div>
              </div>
            </div>

            {/* right-part */}
            <div className="col-span-7 laptop:pl-[124px]">
              <div className="wrap">
                <div data-aos="fade-up">
                  <SectionTitle>
                    Manage it all, in this all{" "}
                    <span className="text text-primary-600">new system</span>
                  </SectionTitle>
                  <SectionText className="">
                    Rapaciously seize adaptive infomediaries and user-centric
                    intellectual capital. Collaboratively unleash market-driven
                    "outside the box" thinking for long-term high-impact
                    solutions.
                  </SectionText>
                </div>

                <div
                  data-aos="fade-up"
                  className="flex flex-col justify-start gap-4 Features-items max-w-[424px]"
                >
                  <ListItem>
                    Collaboratively expedite quality manufactured products via
                    client-focused results.
                  </ListItem>
                  <ListItem>
                    Efficiently initiate world-class applications after
                    client-centric infomediaries.
                  </ListItem>
                  <ListItem>
                    Proactively leverage other resource-leveling rather than
                    inter-mandated networks.
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Content1
