import React from "react"

const AboutCard1 = ({
  title = "50M+",
  text = "Our Happy Customer",
  className,
}) => {
  return (
    <>
      <div
        data-aos="zoom-in"
        className={`rounded-2xl bg-success-50 ${className}`}
      >
        <div className="px-4 py-4 ">
          <div className="flex flex-col gap-3 p-6">
            <h6 className="font-bold text-center text-display-sm text-success-700">
              {title}
            </h6>
            <p className="font-medium text-center text-body-xl text-neutral-500">
              {text}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default AboutCard1
